export const API_Endpoints = {
	CHECK_COUNT: "/api/news/check-breaking-news/count",

	FETCH_NEWS: "/api/news/fetch-breaking-news",
	FETCH_NEWS_LIST: "/api/news/breaking-news/list/",
	FETCH_NEWS_BY_ID: "/api/news/breaking-news/list-by-id/",
	FETCH_NEWS_BY_DATE_PAGE: "/api/news/breaking-news/fetch-by-date-and-page",
	FETCH_NEWS_BY_PAGE: "/api/news/breaking-news/fetch-by-page",
	SEARCH_NEWS: "/api/news/breaking-news/search-by-date-page",
	CREATE_NEWS: "/api/news/breaking-news/create",
	UPDATE_NEWS: "/api/news/breaking-news/update",
	NEWS_STATUS_CHANGE: "/api/news/breaking-news/status/change",
	FETCH_LATEST_BREAKING_NEWS: "/api/news/fetch-latest-breaking-news",
	FETCH_LATEST_BREAKING_NEWS_SCROLL: "/api/news/fetch-latest-breaking-news-scroll",
	SEARCH_NEWS_BY_MEDIA: "/api/news/search-breaking-news-by-media",
	FETCH_NEWS_COUNT_BY_DATE: "/api/news/fetch-news-count-by-date/",
	FETCH_DATE_WISE_NEWS_COUNT: "/api/news/fetch-yearly-date-wise-news-count",

	FETCH_NEWS_SCROLL: "/api/news/fetch-breaking-news-scroll/",
	SET_NEWS_SCROLL: "/api/news/set-breaking-news-scroll",
	NEWS_SCROLL_LIST: "/api/news/fetch-scroll-list",
	DELETE_NEWS_SCROLL_BY_ID: "/api/news/delete-news-scroll/",

	USER_LOGIN: "/api/user/login",
	USER_REGISTRATION: "/api/user/registration",
	FETCH_USERS_LIST: "/api/user/users-list",
	CHANGE_USER_STATUS: "/api/user/user-status-change",
	UPDATE_USER_ACCOUNT: "/api/user/user-account-update",
	
	FETCH_PERMISSION_LIST: "/api/user/permission-list",

	CREATE_MEDIA: "/api/media/new",
	SEARCH_MEDIA: "/api/media/search",
	MEDIA_LIST: "/api/media/list",
	MEDIA_NAMES: "/api/media/names/list",
	FOREIGN_MEDIA_NAMES: "/api/media/foreign/names/list",
	UPDATE_MEDIA_NAME: "/api/media/update",
	MEDIA_STATUS_CHANGE: "/api/media/status/change",
}
