import { getFromStorage } from "../components/utility/Storage"
import { API_Endpoints } from "./APIEndpoints"
import axios from "axios"

const client = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	headers: {
		'Content-Type': 'application/json',
	}
})

const user = getFromStorage("dnr_a")

const admin = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer:${user?.auth}`,
	}
})

export const fetchNews = async () => {
	try {
		const res = await client.get(API_Endpoints.FETCH_NEWS)
		return res.data
	} catch (error) {
		console.log('FETCH_News', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsScroll = async (payload) => {
	try {
		const res = await client.get(API_Endpoints.FETCH_NEWS_SCROLL + payload)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_SCROLL', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchLatestNewsScroll = async (payload) => {
	try {
		const res = await client.get(API_Endpoints.FETCH_LATEST_BREAKING_NEWS_SCROLL)
		return res.data
	} catch (error) {
		console.log('FETCH_LATEST_BREAKING_NEWS_SCROLL', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const setNewsScroll = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.SET_NEWS_SCROLL, payload)
		return res.data
	} catch (error) {
		console.log('SET_NEWS_SCROLL', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsScrollList = async () => {
	try {
		const res = await client.get(API_Endpoints.NEWS_SCROLL_LIST)
		return res.data
	} catch (error) {
		console.log('NEWS_SCROLL_LIST', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const deleteNewsScrollById = async (payload) => {
	try {
		const res = await admin.delete(API_Endpoints.DELETE_NEWS_SCROLL_BY_ID + payload)
		return res.data
	} catch (error) {
		console.log('DELETE_NEWS_SCROLL_BY_ID', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsByDatePage = async (payload) => {
	try {
		const res = await client.get(
			`${API_Endpoints.FETCH_NEWS_BY_DATE_PAGE}/${payload.date}/${payload.page}`
		)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_BY_DATE_PAGE', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsByPage = async (page) => {
	try {
		const res = await client.get(
			`${API_Endpoints.FETCH_NEWS_BY_PAGE}/${page}`
		)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_BY_PAGE', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsListForAdmin = async (payload) => {
	try {
		const res = await admin.get(API_Endpoints.FETCH_NEWS_LIST + payload)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_LIST', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchLatestNews = async () => {
	try {
		const res = await admin.get(API_Endpoints.FETCH_LATEST_BREAKING_NEWS)
		return res.data
	} catch (error) {
		console.log('FETCH_LATEST_BREAKING_NEWS', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const fetchNewsListById = async (payload) => {
	try {
		const res = await admin.get(API_Endpoints.FETCH_NEWS_BY_ID + payload)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_BY_ID', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const createNews = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.CREATE_NEWS, payload)
		return res.data
	} catch (error) {
		console.log('CREATE_NEWS', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const updateNews = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.UPDATE_NEWS, payload)
		return res.data
	} catch (error) {
		console.log('UPDATE_NEWS', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = 'FAIL'

		return res
	}
}

export const checkNewNews = async () => {
	try {
		const res = await client.get(API_Endpoints.CHECK_COUNT)
		return res.data
	} catch (error) {
		console.log('CHECK_COUNT', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const searchNewsByDate = async (payload) => {
	try {
		const res = await client.get(`${API_Endpoints.SEARCH_NEWS}/${payload.date}/${payload.page}`)
		return res.data
	} catch (error) {
		console.log('CHECK_COUNT', error.message);
		const res = {}
		res.news = null
		res.flag = 'FAIL'

		return res
	}
}

export const changeNewsStatus = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.NEWS_STATUS_CHANGE, payload)
		return res.data
	} catch (error) {
		console.log('NEWS_STATUS_CHANGE', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const userLogin = async (payload) => {
	try {
		const res = await client.post(API_Endpoints.USER_LOGIN, payload)
		return res.data
	} catch (error) {
		console.log('USER_LOGIN', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const userRegistration = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.USER_REGISTRATION, payload)
		return res.data
	} catch (error) {
		console.log('USER_REGISTRATION', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchPermissionList = async () => {
	try {
		const res = await client.get(API_Endpoints.FETCH_PERMISSION_LIST)
		return res.data
	} catch (error) {
		console.log('FETCH_PERMISSION_LIST', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetcNewsCountByDate = async (payload) => {
	try {
		const res = await client.get(API_Endpoints.FETCH_NEWS_COUNT_BY_DATE + payload)
		return res.data
	} catch (error) {
		console.log('FETCH_NEWS_COUNT_BY_DATE', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchYearlyDateWiseNewsCount = async () => {
	try {
		const res = await client.get(API_Endpoints.FETCH_DATE_WISE_NEWS_COUNT)
		return res.data
	} catch (error) {
		console.log('FETCH_DATE_WISE_NEWS_COUNT', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchUsersList = async () => {
	try {
		const res = await admin.get(API_Endpoints.FETCH_USERS_LIST)
		return res.data
	} catch (error) {
		console.log('FETCH_USERS_LIST', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const userStatusChange = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.CHANGE_USER_STATUS, payload)
		return res.data
	} catch (error) {
		console.log('CHANGE_USER_STATUS', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const userAccountUpdate = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.UPDATE_USER_ACCOUNT, payload)
		return res.data
	} catch (error) {
		console.log('UPDATE_USER_ACCOUNT', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const createNewMedia = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.CREATE_MEDIA, payload)
		return res.data
	} catch (error) {
		console.log('CREATE_MEDIA', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchMediaList = async (payload) => {
	try {
		const res = await admin.get(API_Endpoints.MEDIA_LIST + `/${payload}`)
		return res.data
	} catch (error) {
		console.log('MEDIA_LIST', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchMediaNames = async () => {
	try {
		const res = await admin.get(API_Endpoints.MEDIA_NAMES)
		return res.data
	} catch (error) {
		console.log('MEDIA_NAMES', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const fetchForeignMediaNames = async () => {
	try {
		const res = await admin.get(API_Endpoints.FOREIGN_MEDIA_NAMES)
		return res.data
	} catch (error) {
		console.log('MEDIA_NAMES', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const updateMediaName = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.UPDATE_MEDIA_NAME, payload)
		return res.data
	} catch (error) {
		console.log('UPDATE_MEDIA_NAME', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const changeMediaStatus = async (payload) => {
	try {
		const res = await admin.patch(API_Endpoints.MEDIA_STATUS_CHANGE, payload)
		return res.data
	} catch (error) {
		console.log('MEDIA_STATUS_CHANGE', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const searchMedia = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.SEARCH_MEDIA, payload)
		return res.data
	} catch (error) {
		console.log('SEARCH_MEDIA', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}

export const searchNewsByMedia = async (payload) => {
	try {
		const res = await admin.post(API_Endpoints.SEARCH_NEWS_BY_MEDIA, payload)
		return res.data
	} catch (error) {
		console.log('SEARCH_MEDIA', error.message);
		const res = {}
		res.msg = "Something went wrong"
		res.flag = "FAIL"

		return res
	}
}